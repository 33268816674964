/* [class~='ant-col'] {
  background: transparent;
  border: 0;
}
[class~='ant-col'] > div {
  height: 120px;
  font-size: 14px;
  line-height: 120px;
  background: #0092ff;
  border-radius: 4px;
} */
pre {
  padding: 8px 16px;
  font-size: 13px;
  background: #f9f9f9;
  border-radius: 6px;
}
pre.demo-code {
  direction: ltr;
}
.ant-col {
  padding: 0;
}
[data-theme="dark"] [class~='ant-col'] > div {
  background: #028ac8;
}

.gutter-box {
  padding: 8px 0;
  background: #00a0e9;
}

[data-theme="dark"] .gutter-box {
  background: #028ac8;
}